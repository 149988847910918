import React from 'react';
import styled from 'styled-components';
import {StyledMainButton} from './components';
import {StaticImage} from 'gatsby-plugin-image';

const StyledTicket = styled.div`
  position: relative;
  margin: 1rem;
  max-width: 300px;
  text-align: center;
  text-transform: uppercase;
  overflow: hidden;
  user-select: none;

  &::before,
  &::after,
  .corner-top-left,
  .corner-top-right,
  .corner-bottom-left,
  .corner-bottom-right {
    content: '';
    z-index: 1;
    position: absolute;
    height: 36px;
    width: 36px;
    overflow: hidden;
    background-color: white;
  }

  &::before {
    top: 0;
    left: 0;
    border-bottom-right-radius: 90%;
  }

  &::after {
    top: 0;
    right: 0;
    border-bottom-left-radius: 90%;
  }

  .corner-bottom-left {
    border-right: 2px solid #000;
    bottom: 0;
    left: 0;
    border-top-right-radius: 90%;
  }

  .corner-bottom-right {
    border-left: 2px solid #000;
    bottom: 0;
    right: 0;
    border-top-left-radius: 90%;
  }

  .top-container {
    padding: 2rem 1rem;

    h3 {
      margin-top: 0;
      font-family: 'Montserrat', sans-serif;
      font-size: 1.5rem;
    }

    .price {
      font-size: 2.5rem;
      font-weight: bold;
      margin: 1rem 0;
      color: #e74c3c;
    }

    .sold-out {
      color: red;
      font-weight: bold;
    }
  }

  .bottom-container {
    background-color: #fff;
    border: 1px solid #000;
    border-top: 2px dashed #000;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;

    /* &:before {
      content: '';
      position: absolute;
      top: -5px;
      width: 100%;
      height: 10px;
      background-image: radial-gradient(circle at center, #fff 4px, transparent 5px);
      background-size: 10px 10px;
      left: 0;
      right: 0;
    } */
  }

  .sold-out-stamp {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) rotate(-15deg);
    font-size: 3rem;
    font-weight: bold;
    color: red;
    border: 5px solid red;
    padding: 10px;
    text-transform: uppercase;
    opacity: 2.5; // Increased opacity to counteract parent's opacity
    pointer-events: none;
    z-index: 1;
  }

  @keyframes pulse {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(1.1);
    }
    100% {
      transform: scale(1);
    }
  }
`;

type TicketType = {
  title: string;
  price: string;
  message: string;
  colors: {
    background: string;
    text: string;
  };
  available: boolean;
  isSoldOut: boolean;
};

interface TicketProps {
  ticket: TicketType;
  handleClick: () => void;
}

const Ticket: React.FC<TicketProps> = ({ticket, handleClick}) => {
  const {title, price, message, available, colors, isSoldOut} = ticket;

  return (
    <StyledTicket>
      {isSoldOut && <div className="sold-out-stamp">SOLD OUT</div>}
      <div
        style={{
          backgroundColor: colors.background,
          color: colors.text,
          opacity: available ? 1 : 0.4
        }}
      >
        <div className="corner-bottom-left"></div>
        <div className="corner-bottom-right"></div>
        <div className="top-container">
          <div className="message">{message}</div>
          <h3>{title}</h3>
          <StaticImage
            className="logo-img"
            width={160}
            height={184}
            objectFit="contain"
            src="../images/logo-large.png"
            alt="Armada JS Logo"
            placeholder="none"
          />
          <div className="price" style={{backgroundColor: colors.text, color: colors.background}}>
            {price}
          </div>
        </div>
        <div className="bottom-container">
          <StyledMainButton onClick={() => handleClick()} disabled={!available}>
            BUY NOW
          </StyledMainButton>
        </div>
      </div>
    </StyledTicket>
  );
};

export default Ticket;
