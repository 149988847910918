import React from 'react';
import styled from 'styled-components';
import {
  ArrowLeftCircle as ArrowLeftCircleIcon,
  XCircle as XCircleIcon,
  User as UserIcon
} from 'react-feather';
import {StyledMainButton, FientaLinkButton} from './components';
import {ReactNode} from 'react';

// Define a type for the modal step configuration
type ModalStep = {
  title: string;
  content: ReactNode;
  buttons: Array<{
    label: string;
    action: () => void;
    type: 'main' | 'fienta';
    url?: string;
  }>;
};

enum PurchaseState {
  PENDING = 'pending',
  IS_COMPANY = 'is_company',
  IS_DOMESTIC_COMPANY = 'is_domestic_company'
}

function getModalSteps(
  handleClick: (newState: PurchaseState) => void
): Record<PurchaseState, ModalStep> {
  return {
    // Define the configuration object
    [PurchaseState.PENDING]: {
      title: 'Are you purchasing on behalf of a company (legal entity) or as an individual?',
      content: (
        <p>
          For companies we can provide an invoice for the tickets that can be paid directly to our
          account. <br />
          For any other questions, please reach out to:{' '}
          <a href="mailto:tickets@armada-js.com">tickets@armada-js.com</a>
        </p>
      ),
      buttons: [
        {label: 'COMPANY', action: () => handleClick(PurchaseState.IS_COMPANY), type: 'main'},
        {
          label: 'INDIVIDUAL',
          action: () => {},
          type: 'fienta',
          url: 'https://fienta.com/armada-js-2024'
        }
      ]
    },
    [PurchaseState.IS_COMPANY]: {
      title: 'Is your company registered in Serbia?',
      content: (
        <p>
          For companies we can provide an invoice for the tickets that can be paid directly to our
          account. <br />
          For any other questions, please reach out to:{' '}
          <a href="mailto:tickets@armada-js.com">tickets@armada-js.com</a>
        </p>
      ),
      buttons: [
        {label: 'YES', action: () => handleClick(PurchaseState.IS_DOMESTIC_COMPANY), type: 'main'},
        {label: 'NO', action: () => {}, type: 'fienta', url: 'https://fienta.com/armada-js-2024'}
      ]
    },
    [PurchaseState.IS_DOMESTIC_COMPANY]: {
      title: '',
      content: (
        <iframe
          src="https://docs.google.com/forms/d/e/1FAIpQLSdKHotNXR7M5vSnK9YvLXf0ZAjufZKE5N3aA62p4B5VN8Lbxg/viewform?embedded=true"
          style={{
            width: '100%',
            height: 'calc(100vh - 64px)', // Subtract header height
            maxWidth: '640px',
            maxHeight: '800px',
            border: 'none',
            display: 'block',
            margin: '0 auto'
          }}
        >
          Loading…
        </iframe>
      ),
      buttons: []
    }
  };
}

const StyledPurchaseTicketModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;

  .modal-content {
    background: white;
    padding: 2rem;
    border-radius: 8px;
    text-align: center;
    // Add responsive width
    width: 90%; // Default width for mobile
    max-width: 500px; // Maximum width for larger screens

    // Media query for desktop screens
    @media (min-width: 768px) {
      width: 70%; // Wider on desktop
      max-width: 800px; // Larger maximum width for desktop
    }
  }

  .modal-header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .button-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
`;

const StyledModalHeaderButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  background-color: transparent;
  color: #000;
  border: none;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  transition: background-color 0.3s;

  &:hover {
    color: #43d3e8;
  }

  &:focus {
    outline: none;
  }
`;

interface PurchaseTicketModalProps {
  isOpen: boolean;
  onClose: () => void;
}

const PurchaseTicketModal: React.FC<PurchaseTicketModalProps> = ({isOpen, onClose}) => {
  const [purchaseState, setPurchaseState] = React.useState<PurchaseState>(PurchaseState.PENDING);

  const handleClick = (newState: PurchaseState) => {
    setPurchaseState(newState);
  };

  const handleClose = () => {
    setPurchaseState(PurchaseState.PENDING);
    onClose();
  };

  if (!isOpen) return null;

  const currentStep = getModalSteps(handleClick)[purchaseState];

  return (
    <StyledPurchaseTicketModal>
      <div className="modal-content">
        <div className="modal-header-container">
          {purchaseState !== PurchaseState.PENDING ? (
            <StyledModalHeaderButton onClick={() => handleClick(PurchaseState.PENDING)}>
              <ArrowLeftCircleIcon size={24} /> Back
            </StyledModalHeaderButton>
          ) : (
            <div />
          )}
          <StyledModalHeaderButton onClick={handleClose}>
            <XCircleIcon size={24} />
          </StyledModalHeaderButton>
        </div>
        {currentStep.title && <h2>{currentStep.title}</h2>}
        {currentStep.content}
        <div className="button-container">
          {currentStep.buttons.map((button, index) =>
            button.type === 'main' ? (
              <StyledMainButton key={index} onClick={button.action}>
                {button.label}
              </StyledMainButton>
            ) : (
              <FientaLinkButton key={index} label={button.label} url={button.url || ''} />
            )
          )}
        </div>
      </div>
    </StyledPurchaseTicketModal>
  );
};

export default PurchaseTicketModal;
